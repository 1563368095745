import React from 'react';
import cn from './styles.module.scss'
interface IPopper {
    children: React.ReactNode
    isOpen: boolean
    setIsOpen: Function
}

const Popper: React.FC<IPopper> = ({ children, isOpen, setIsOpen }) => {

    if (!isOpen) return null
    return (
        <div className={cn[`overlay`]} onClick={() => setIsOpen(false)}>
            <div className={cn["overlay-content"]} 
            onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default Popper;
