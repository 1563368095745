import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainBackground from './MainBackground.png'
import MainLogo from '../../assets/MainLogo.svg'
import { Experts } from '../../assets/experts'
import ArticleImg from '../../assets/ArticleImg.png'

import { getTranslate } from '../../helpers'

import Img1 from './Img1.png'

import cn from './main.module.scss'

const AboutUs = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    const title = t('main.title').split(' ')
    const author: any = Experts.filter(item => item.author === true)

    function scrollToSection() {
        const section = document.getElementById('section3');
        if (!section) return
        section.scrollIntoView({ behavior: 'smooth' });
    }


    return (
        <>
            <div className={cn.root} id={'sectionTop'} >
                <img src={MainBackground}
                    className={cn.back}
                    alt="sp labs" style={{ position: 'absolute' }} />

                <div className={cn.panel}>

                    <div className={cn['panel-text']}>
                        <div className={cn['panel-text-container']}>
                            <img src={MainLogo} alt="sp labs" className={cn.icon} />
                            <h1>
                                {
                                    title.map((word, index) =>
                                        <span key={index} style={index === 0 ? { fontWeight: 700 } : { fontStyle: 'Italic', fontWeight: 400 }}>
                                            {word}
                                        </span>)
                                }
                            </h1>
                        </div>

                        <p className={cn['panel-text-subtitle']}>{t('main.subtitle')}</p>
                    </div>

                    <img className={cn.img} src={Img1} alt='sp labs' style={{ width: '160px', height: '220px', borderRadius: '15px', alignSelf: 'center' }} />
                </div>

                <div id={'section0'} style={{ scrollMarginTop: '40px' }}/>
                {/* intro */}
                <div className={cn[`intro${open ? '-open' : ''}`]} >
                    <div className={cn['intro-columns']}>
                        <div style={{width: '22%'}}  className={cn.hideMob}>
                            <p style={{ fontWeight: 400, fontStyle: "Italic", fontSize: '96px', color: 'rgba(190, 197, 204, 1)' }}>[01]</p>
                        </div>

                        <div className={cn['intro-article']}>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px', width: '100%' }}>
                                <div className={cn['intro-title']} >
                                    <h4>{t('main.intro.title')}</h4>
                                    <h4>{t('main.intro.subtitle')}</h4>
                                </div>

                                {/* snippets */}
                                <div  className={cn.snippets}>
                                    <div className={cn.snippet}>
                                        <p className={cn['snippet-title']}>
                                            {
                                                t('main.intro.snippet1.title').split(' ').map((word, index) =>
                                                    <span key={index} style={index !== 0 ? {} : { fontStyle: 'Italic' }}>
                                                        {word}
                                                    </span>)
                                            }
                                        </p>
                                        <p>{t('main.intro.snippet1.subtitle')}</p>
                                    </div>
                                    <div className={cn.snippet} >
                                        <p className={cn['snippet-title']}>
                                            {
                                                t('main.intro.snippet2.title').split(' ').map((word, index) =>
                                                    <span key={index} style={index !== 0 ? {} : { fontStyle: 'Italic' }}>
                                                        {word}
                                                    </span>)
                                            }
                                        </p>
                                        <p>{t('main.intro.snippet2.subtitle')}</p>
                                    </div>
                                </div>

                                <p className={cn['intro-article-text']}
                                    dangerouslySetInnerHTML={{ __html: t('main.intro.article.text') }} />

                                <img src={ArticleImg} alt='sp labs' />

                                <p style={{ fontSize: "32px", textAlign: 'left', margin: '30px 0 30px 0' }}>{t('main.intro.article.outroText')}</p>

                            </div>
                        </div>

                        <div className={cn['intro-author']} >
                            <img src={author[0].avatarBig ?? author[0].avatar} alt={getTranslate(author[0].name)} />
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p className={cn['intro-author-name']}>{getTranslate(author[0].name)}</p>
                            <p className={cn['intro-author-jobTitle']}>{getTranslate(author[0].jobTitle)}</p>
                            </div>
                        </div>

                    </div>

                    {!open && <div className={cn.blur} />}
                    <div className={cn.buttonContainer} onClick={() => {
                        !open ? setOpen(true) : scrollToSection()
                    }}>
                        <div className={cn['buttonContainer-btn']}>
                            {open ?
                                <>
                                    <p style={{ color: 'white' }}>{t('main.intro.contact')}</p>
                                </>
                                :
                                <>
                                    <div><p>+</p></div>
                                    <p style={{ color: 'white' }}>{t('main.intro.showMore')}</p>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutUs