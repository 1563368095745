import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getTranslate } from '../../helpers'
import { Experts } from '../../assets/experts'
import { contacts } from '../../assets/contacts'
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleRecaptchaKey } from '../../assets/CONSTANTS'
import Popper from '../../helpers/Popper'

import cn from './contacts.module.scss'

const Contacts = () => {
    const { t } = useTranslation()

    const manager = Experts.find((item) => item.manager === true)

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [mainText, setMainText] = useState('');

    const [open, setOpen] = useState(false)

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;

        // Russian phone number validation
        const phoneRegex = /^[+]?[0-9]{1,3}[-s./0-9]*$/;
        if (phoneRegex.test(input) || input === '') {
            setPhoneNumber(input);
        }
    };
    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMainText(e.target.value);
    };

    const handleEnter = (e: React.KeyboardEvent) => {
        // if (e.key === 'Enter' && name !== '' && phoneNumber !== '' && mainText !== '') {
        // handleQuery()
        // }
    };

    // captcha
    const [isCaptchaSuccess, setIsCaptchaSuccess] = useState(false);

    const handleCaptchaSuccess = () => {
        setIsCaptchaSuccess(true);
    };

    const isReady = useMemo(() => {
        return name !== '' && phoneNumber !== '' && mainText !== ''
    }, [name, phoneNumber, mainText])



    const handleMailToClick = () => {

        if (isCaptchaSuccess) {
            // Proceed to open email client if captcha is successful
            const email = `mailto:${contacts.email}`
            const subject = `?subject=${t('contacts.card.mail.subject')}`
            const body = ` &body=${encodeURIComponent(
                t('contacts.card.mail.emailBody', { name: name, phone: phoneNumber, text: mainText })
                    .replaceAll('<br>', '\n')
            )}`
            window.location.href = email + subject + body;
        } else {
            alert(t('status.captcha'));
        }
    };


    return (
        <>
            <div className={cn.root} id={'section3'} style={{ scrollMarginTop: '65px' }}>

                <div className={cn.firstColumn}>
                    <p style={{ fontWeight: 400, fontStyle: "Italic", fontSize: '96px', color: 'rgba(190, 197, 204, 1)' }}>
                        [04]
                    </p>
                    <p className={cn['']}
                        dangerouslySetInnerHTML={{ __html: t('contacts.address') }} />
                    <br />
                    <p className={cn['intro-article-text']}
                        dangerouslySetInnerHTML={{ __html: t('contacts.contacts') }} />
                </div>

                <div className={cn.secondColumn}>

                    <h2>{t('contacts.title').toUpperCase()}</h2>


                    <div className={cn.card}>
                        <div className={cn.manager}>
                            <img src={manager?.avatar} alt={getTranslate(manager?.name)} />
                            <p style={{ fontWeight: 600, fontSize: 28 }}>{getTranslate(manager?.name)}</p>
                            <p style={{ fontWeight: 600, fontSize: 16 }}>{getTranslate(manager?.jobTitle)}</p>
                        </div>

                        <div className={cn.contact}>
                            <h3>{t('contacts.card.title')}</h3>
                            <p style={{ fontSize: 18, fontWeight: 500 }}>{t('contacts.card.subtitle')}</p>

                            <div className={cn.links}>
                                {/* input */}
                                <div className={cn.inputs}>
                                    <div>
                                        <label htmlFor="name">{t('contacts.card.name')}</label>
                                        <input
                                            type="text"
                                            id="name"
                                            placeholder={t('contacts.card.namePlaceholder')}
                                            value={name}
                                            onChange={handleNameChange}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="phoneNumber">{t('contacts.card.phone')}</label>
                                        <input
                                            type="text"
                                            id="phoneNumber"
                                            placeholder={t('contacts.card.phonePlacegolder')}
                                            value={phoneNumber}
                                            onChange={handlePhoneNumberChange}
                                            onKeyDown={handleEnter}
                                        />
                                    </div>
                                </div>
                                <div className={cn.inputs} >
                                    <div style={{ width: '100%' }}>
                                        <label htmlFor="mainText">{t('contacts.card.text')}</label>
                                        <textarea
                                            id="mainText"
                                            placeholder={t('contacts.card.textPlaceholder')}
                                            value={mainText}
                                            onChange={handleTextChange}
                                            onKeyDown={handleEnter}
                                        />
                                    </div>
                                </div>

                                {/* <div className={cn.link} onClick={handlePhoneToClick} >
                                    <button className={cn['btn-call']}>
                                        <p>{t('contacts.card.call')}</p>
                                    </button>
                                </div> */}


                                <div className={cn.link} onClick={() => setOpen(isReady)}>
                                    <button className={cn.btn}
                                        style={isReady ? {} : { cursor: 'not-allowed' }}
                                    >
                                        <p>{t('contacts.card.contact')}</p>
                                    </button>
                                </div>



                            </div>
                            <p style={{ marginLeft: 'auto', marginRight: 'auto' }}>{t('contacts.card.aggreement')}&nbsp;<span style={{ color: '#07C2EB', fontWeight: 600 }}>{t('contacts.card.privacy')}</span></p>
                        </div>

                    </div>
                </div>

                <Popper isOpen={open} setIsOpen={setOpen}>
                    <div className={cn.captcha}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <h4  style={{ maxWidth: '300px' }}>{t('contacts.card.captcha.title')}:</h4>
                            <button className={cn.x} onClick={() => setOpen(false)}>&#10005;</button>
                        </div>
                        <ReCAPTCHA
                            sitekey={GoogleRecaptchaKey}
                            onChange={handleCaptchaSuccess}
                        />

                        <div className={cn.link} onClick={handleMailToClick}>
                            <button className={cn.btn}
                            //  style={isReady ? {} : { cursor: 'not-allowed' }}
                            >
                                <p>{t('contacts.card.captcha.send')}</p>
                            </button>
                        </div>
                    </div>

                </Popper>

            </div>
        </>
    )
}
export default Contacts