import React from 'react'
import { useTranslation } from 'react-i18next'
import { Experts as ExpertsData } from '../../assets/experts'
import { getTranslate } from '../../helpers'
import Popper from '../../helpers/Popper'

import cn from './experts.module.scss'

const Experts = () => {
    const { t } = useTranslation()
    const [more, setMore] = React.useState(false)
    const expertsData = React.useMemo(() =>
        more ? ExpertsData : ExpertsData.slice(0, 6), [more])

    const [activeExpert, setActiveExpert] = React.useState(0)
    const [open, setOpen] = React.useState(false)
    return (
        <>
            <div className={cn.root} id={'section1'} style={{ scrollMarginTop: '60px' }}>

                <div className={cn.firstColumn}>
                    <p style={{ fontWeight: 400, fontStyle: "Italic", fontSize: '96px', color: 'rgba(190, 197, 204, 1)' }}>[02]</p>
                    <p>{t('experts.subtitle')}</p>
                </div>

                <div className={cn.secondColumn}>
                    <h2>{t('experts.title').toUpperCase()}</h2>
                    <p className={cn.hideDesk}>{t('experts.subtitle')}</p>
                    <div className={cn.container}>

                        {expertsData.map((expert, i) => (
                            <div className={cn.card} key={expert.name.ru + i} onClick={() => {
                                setActiveExpert(i)
                                setOpen(true)
                            }}>
                                <img src={expert.avatar} alt={getTranslate(expert.name)} />
                                <p className={cn['text-title']} >{getTranslate(expert.name)}</p>
                                <p className={cn['text-sub']} >{getTranslate(expert.jobTitle)}</p>

                                <p className={cn['text-description']}>{getTranslate(expert.description)}</p>
                                <button className={cn.button}>
                                    <div className={cn['button-plus']}>+</div>
                                    <p className={cn['button-text']}>{t('experts.btn')}</p>
                                </button>
                            </div>
                        ))}
                        {!more && <button className={cn.moreButton} onClick={() => setMore(true)} style={ExpertsData.length > 6 ? {} : { display: 'none' }}>
                            <div className={cn['button-plus']}>+</div>
                            <p className={cn['button-text']}>{t('experts.btn')}</p>
                        </button>}

                    </div>
                </div>

                <Popper isOpen={open} setIsOpen={setOpen}>
                    <div className={cn.card} style={{ width: 'unset' }} key={expertsData[activeExpert].name.ru}
                    >
                        <img src={expertsData[activeExpert].avatar} alt={getTranslate(expertsData[activeExpert].name)} />
                        <p style={{ fontSize: 28, fontWeight: 600 }}>{getTranslate(expertsData[activeExpert].name)}</p>
                        <p style={{ fontSize: 16 }}>{getTranslate(expertsData[activeExpert].jobTitle)}</p>

                        <p >{getTranslate(expertsData[activeExpert].description)}</p>

                    </div>
                </Popper>
            </div>
        </>
    )
}
export default Experts