import React from 'react'
import { useTranslation } from 'react-i18next'
import { getTranslate } from '../../helpers'
import Popper from '../../helpers/Popper'
import { IPrograms, Programs as ProgramsData } from '../../assets/programs'


import cn from './programs.module.scss'

const Programs = () => {
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false)

    const [currentProgram, setCurrentProgram] = React.useState(ProgramsData[0])
    return (
        <>
            <div className={cn.root} id={'section2'} style={{ scrollMarginTop: '65px' }}>

                <div className={cn.firstColumn}>
                    <p style={{ fontWeight: 400, fontStyle: "Italic", fontSize: '96px', color: 'rgba(190, 197, 204, 1)' }}>
                        [03]
                    </p>
                    <p>{t('programs.subtitle')}</p>
                </div>

                <div className={cn.secondColumn}>
                    <h2>{t('programs.title').toUpperCase()}</h2>
                    <p className={cn.hideDesk}>{t('programs.subtitle')}</p>

                    <div className={cn.container}>

                        {ProgramsData.map((item: IPrograms, i) => (
                            <div key={getTranslate(item.title) + i} className={cn[`card${item.big ? '-big' : ''}`]}
                                onClick={() => {
                                    setCurrentProgram(item)
                                    setOpen(true)
                                }}
                            >
                                <img src={item.img} alt={getTranslate(item.title)} />
                                <div className={cn['card-text']}>
                                    <p style={{ fontWeight: 600, fontSize: 22, maxWidth: '80%' }}>{getTranslate(item.title)}</p>
                                    <div className={cn['plus']}>&#x2b;</div>
                                </div>
                            </div>

                        ))}


                    </div>
                </div>

                <Popper isOpen={open} setIsOpen={setOpen}>
                    <div key={getTranslate(currentProgram.title)} className={cn[`card-popper`]} >
                        <img
                            className={currentProgram.big ? cn['card-popper-img-big'] : cn['card-popper-img']}
                            src={currentProgram.img} alt={getTranslate(currentProgram.title)} />
                        <div style={{ marginLeft: "auto", marginRight: 'auto' }}>
                            <p style={{ fontWeight: 600, fontSize: 22 }}>{getTranslate(currentProgram.title)}</p>
                        </div>
                        <div style={{ textAlign: 'left', }}>
                            <p dangerouslySetInnerHTML={{ __html: getTranslate(currentProgram.description) }} />
                        </div>
                    </div>
                </Popper>
            </div>
        </>
    )
}
export default Programs