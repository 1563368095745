import MainLogo from '../../assets/MainLogo.svg'
import { useTranslation } from 'react-i18next'
import { contacts } from '../../assets/contacts'

import cn from './footer.module.scss'
const Footer = () => {
    const { t } = useTranslation()
    const title = t('main.title').split(' ')

    return (
        <>
            <div className={cn.root}>
                <div className={cn.first}>
                    <div className={cn['first-title']}>
                        <div>
                            <img src={MainLogo} alt={'sp labs'} />
                            <p>
                                {
                                    title.map((word, index) =>
                                        <span key={index} style={index === 0 ? { fontWeight: 700 } : { fontStyle: 'Italic', fontWeight: 400 }}>
                                            {word}
                                        </span>)
                                }
                            </p>
                        </div>
                        <div className={cn.founded}>
                            <p>{t('footer.founded')}</p>
                        </div>
                    </div>
                    <div style={{ maxWidth: '40%', textAlign: 'left', marginTop: '20px' }}>
                        <p>{t('main.subtitle')}</p>
                    </div>
                    <p style={{ marginTop: 'auto' }} className={cn.hideMob}>{t('footer.offer')}</p>
                </div>
                <div className={cn.second}>
                    <div style={{ textAlign: 'left' }}>
                        <p
                            dangerouslySetInnerHTML={{ __html: t('contacts.address') }} />
                        <br />
                        <p
                            dangerouslySetInnerHTML={{ __html: t('contacts.contacts') }} />
                    </div>
                    <div>
                        {contacts.socialMedias.map((item: any, i) => (
                                <a key={item.link+i} className={cn.link} href={item.link} target={'_blank'} rel="noreferrer">
                                        <img src={item.icon} alt={item.link} />
                                </a>
                        ))}
                    </div>
                    <p style={{ marginTop: '30px', textAlign: 'left' }} className={cn.hideDesk}>{t('footer.offer')}</p>

                </div>
            </div>
        </>
    )
}
export default Footer