import React from 'react';
import './App.css';
import Header from './blocks/header/header';
import AboutUs from './blocks/aboutUs'
import Experts from './blocks/experts'
import Programs from './blocks/programs'
import Contacts from './blocks/contacts'
import Footer from './blocks/footer'
function App() {
  return (
    <div className="App">
      <Header />
      <AboutUs />
      <Experts />
      <Programs />
      <Contacts />
      <Footer />
    </div>
  );
}

export default App;
