import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import MainLogo from '../../assets/MainLogo.svg'
import Globe from './globe.svg'
import cn from './header.module.scss'

const Header = () => {
    const { t } = useTranslation()
    const [activeNav, setActiveNav] = useState(0)
    const [isScrolled, setIsScrolled] = useState(false)
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    function changeLang() {
        i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')
    }

    const navs = [
        {
            title: t('navigation.aboutus'),
            tag: ''
        },
        {
            title: t('navigation.team'),
            tag: ''
        },
        {
            title: t('navigation.programs'),
            tag: ''
        },
        {
            title: t('navigation.contacts'),
            tag: ''
        },
    ]

    useEffect(() => {
        if (!global.document) return
        const handler = () => {
            if (global.document.documentElement.scrollTop > 40 && !isScrolled) {
                setIsScrolled(true)
            } else if (
                global.document.documentElement.scrollTop <= 40 &&
                isScrolled
            ) {
                setIsScrolled(false)
            }
        }

        window.addEventListener("scroll", handler)
        return () => window.removeEventListener("scroll", handler)
    }, [isScrolled])

    function scrollToSection(sectionId: number | string) {
        const section = document.getElementById('section' + sectionId);
        if (!section) return
        section.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <>
            <header className={cn[`root${isScrolled ? '-scrolled' : ''}`]}>
                <div className={cn.langContainer}>
                    <div onClick={() => scrollToSection('Top')} style={{ cursor: 'pointer' }}>
                        <img src={MainLogo} alt='logo' className={cn.icon} />
                    </div>

                    <div className={cn.lang} onClick={changeLang}>
                        <img src={Globe} alt='globe' />
                        {i18n.language === 'ru' ? <p>RU</p> : <p>EN</p>}
                    </div>
                </div>
                <nav className={cn.nav}>
                    {navs.map((item, i) => (
                        <div
                            className={cn[`nav-item${activeNav === i ? '-active' : ''}`]}
                            key={item.title}
                            onClick={() => {
                                scrollToSection(i)
                                setActiveNav(i)
                            }}>

                            <p>[0{i + 1}]</p>
                            <p>{item.title}</p>

                        </div>
                    ))}
                </nav>
            </header>

            <div className={cn.burgerContainer}>
                <div onClick={() => scrollToSection('Top')} style={{ cursor: 'pointer' }}>
                    <img src={MainLogo} alt='logo' className={cn.icon} />
                </div>
                
                <div className={cn.burger} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    {!isMenuOpen ?
                        <>
                            <div className={cn.burgerLine} />
                            <div className={cn.burgerLine} />
                            <div className={cn.burgerLine} />
                        </>
                        :
                        <>
                            &#x2715;
                        </>
                    }
                </div>
                {isMenuOpen && (
                    <div className={cn.menu}>
                        <div className={cn.menuNav}>
                            {navs.map((item, i) => (
                                <div
                                    className={cn[`nav-item${activeNav === i ? '-active' : ''}`]}
                                    key={item.title}
                                    onClick={() => {
                                        scrollToSection(i)
                                        setActiveNav(i)
                                        setIsMenuOpen(false)
                                    }}>

                                    <p>[0{i + 1}]</p>
                                    <p>{item.title}</p>

                                </div>
                            ))}
                            <div className={cn.lang} onClick={changeLang}>
                                <img src={Globe} alt='globe' />
                                {i18n.language === 'ru' ? <p>RU</p> : <p>EN</p>}
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </>
    )
}
export default Header