export const contacts = {
    email: 'support@sugarpulse.io',
    phoneNumber: '+971583055469',
    socialMedias: [
        {
            link: 'https://www.instagram.com/sugarpulse.io/',
            icon: require('./general/inst.png')
        },
        {
            link: ' https://www.youtube.com/channel/UCDyB3gONvVmGEp7C0ib8pqQ',
            icon: require('./general/yt.png')
        },
        {
            link: 'https://t.me/+gFHZ-2kmQYFkY2Fj',
            icon: require('./general/tg.png')
        },

    ]
}