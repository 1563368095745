interface IText{
    ru: string,
    en: string
}
export interface IPrograms {
    title: IText,
    description: IText,
    img: string, //img: require('./temp2.png'),
    big: boolean //for big ones
}
export const Programs:IPrograms[] = [
    {
        title: {
            ru: 'Инновационные методы компенсации СД1',
            en: 'Innovative Methods for Type 1 Diabetes Management',
        },
        description: {
            ru: '1. Генетика диабета 1 типа <br>2. Нутрициология, гликирование белков, управление питанием <br>3. Современный подход к компенсации: A1c, HbA1c, вариабельность уровня глюкозы <br>4. Шприц-ручки или помповая терапия?  <br>5. Введение в пользование «петлевого контура», практикум <br>6. Спорт и диабет 1 типа.   <br>7. Психология диабета.  <br>8. Современные и перспективные исследования лечения диабета 1 типа – знакомство с программами ведущих мировых центров эндокринологии, (подача заявок на участие в экспериментальной терапии) <br>3 практикума: психология, петля, питание',
            en: `1. Genetics of Type 1 Diabetes
2. Nutrition Science, Protein Glycation, and Dietary Management
3. Modern Approach to Diabetes Management: A1c, HbA1c, and Glucose Variability
4. Insulin Pens or Pump Therapy?
5. Introduction to the Use of a AndroidAPS, DIY Loop, iAPS - Practical Workshop
6. Sports and Type 1 Diabetes
7. Psychology of Diabetes
8. Current and Prospective Research in Type 1 Diabetes Treatment – Overview of Programs from Leading Global Endocrinology Centers (Application for Participation in Experimental Therapy)

Three Practical Workshops: Psychology, AID System, Nutrition
`,
        },
        img: require('./program1.png'),
        big: true
    },
    {
        title: {
            ru: 'Ремиссия диабета 2 типа',
            en: 'Type 2 Diabetes Remission',
        },
        description: {
            ru: '1. Преддиабет. Диабет 2 типа. Скрининг и прогнозы. Индивидуальные особенности.<br> 2. Понятие ремиссии диабета 2 типа современный подход.<br> 3. Питание и диетология. CGM<br> 4. Современная фармокология.<br> 5. Стабилизация показателей и сохранение здорового состояния организма.<br> 6. Психология диабета 2 типа.<br> 7. Практикум.',
            en: `Prediabetes. Type 2 Diabetes. Screening and Prognoses. Individual Characteristics.
Concept of Type 2 Diabetes Remission: Contemporary Approach.
Nutrition and Dietetics. Continuous Glucose Monitoring (CGM).
Modern Pharmacology.
Stabilization of Metrics and Maintenance of Healthy Organism Status.
Psychology of Type 2 Diabetes.
Workshop.
`,
        },
        img: require('./program3.png'),
        big: false
    },
    {
        title: {
            ru: 'Медицинское похудение',
            en: 'Medical Weight Loss',
        },
        description: {
            ru: '1. Генетика лишнего веса.<br> 2. Современный подход. Индивидуальные особенности. Виды ожирения.<br> 3. Эндокринология и фармакология современный подход.<br> 4. Диетология, питание, особенности и новые исследования.<br> 5. Индивидуальный трек. Психология. Цели.<br> 6. Инновационные подходы к щадящей корректировке лишнего веса.',
            en: `Genetics of Excess Weight.
Modern Approach: Individual Characteristics and Types of Obesity.
Endocrinology and Pharmacology: Contemporary Approaches.
Dietetics and Nutrition: Features and New Research.
Individual Track: Psychology and Goals.
Innovative Approaches to Gentle Weight Correction.
`,
        },
        img: require('./program2.png'),
        big: false
    },
    {
        title: {
            ru: 'Омоложение организма современные инновационные методики',
            en: 'Rejuvenation of the Body: Modern Innovative Techniques',
        },
        description: {
            ru: '1. Генетика возрастных изменений.<br> 2. Современные исследования и результаты.<br> 3. Эндокринология - гормональный фон возрастных изменений.<br> 4. Перспективные исследования корректировки возрастных изменений.',
            en: `Genetics of Age-Related Changes.
Current Research and Results.
Endocrinology: Hormonal Profile of Age-Related Changes.
Prospective Studies on the Correction of Age-Related Changes.
`,
        },
        img: require('./program4.png'),
        big: true
    },

]