interface IText{
    ru: string,
    en: string
}
interface IExperts {
    name: IText,
    jobTitle: IText,
    description: IText,
    avatar: string, //avatar: require('./IvanIvanov.png'),
    avatarBig?: string,
    author?: boolean //author of an article
    manager?: boolean // to put in contacts
}
export const Experts:IExperts[] = [
    {
        name: {
            ru: 'Элина Кочарян',
            en: 'Elina Kocharyan',
        },
        jobTitle: {
            ru: 'CEO SPLabs, врач-эндокринолог, диетолог',
            en: 'CEO SPLabs, Endocrinologist, Nutritionist'
        },
        description: {
            ru: `Врач, Кочарян Элина Эрнестовна, окончила Ростовский государственный медицинский университет в 2013 году.
Проходила ординатуру на кафедре РостГМУ и в ГБУ РО ОКБ №2 города Ростова-на-Дону с 2013г по 2015г.
Врачом-эндокринологом начала работать с 09.11.2015 года в эндокринологическом центре ГБУ РО ОКБ №2 города Ростова-на-Дону.
В 2016 году получила диплом диетолога и начала работать по совместительству врачом – диетологом.
Специалист, владеющий необходимыми теоретическими знаниями и практическими навыками,
уровень которых постоянно повышает, посещая конгрессы, конференции, семинары, научно-практические симпозиумы,
практикумы, курсы повышения квалификации. Имеет печатные работы, методические рекомендации для терапевтов по
некоторым эндокринологическим аспектам. Навыки и достижения: курация стационарных больных, консультации
поликлинических пациентов, чтение лекций в «школе для больных диабетом, ожирением», обучение врачей-ординаторов,
установка и настройка инсулиновых помп, установка и анализ систем непрерывного суточного мониторирования глюкозы крови,
консультативные выезды в составе врачебных бригад по оказанию высококвалифицированной медицинской
помощи сельским жителям, работа в составе бригады выездного диабетического модуля, оказание помощи больным в
неотложных состояниях, участие в научно-практических конференциях, выпуск методических пособий для больных
сахарным диабетом.`,
            en: `Dr. Elina Kocharyan graduated from Rostov State Medical University (RostGMU) in 2013. She completed her residency at the Department of RostGMU and at the Regional Clinical Hospital No. 2 in Rostov-on-Don from 2013 to 2015. She began working as an endocrinologist in November 2015 at the Endocrinology Center of the Regional Clinical Hospital No. 2 in Rostov-on-Don.
In 2016, she obtained a diploma in dietetics and started working concurrently as a dietitian. Dr. Kocharyan continuously enhances her theoretical knowledge and practical skills by attending congresses, conferences, seminars, scientific-practical symposiums, workshops, and advanced training courses.
She has published works and methodological recommendations for therapists on various endocrinological aspects. Her skills and achievements include:
-Managing hospitalized patients
-Consulting outpatient clinic patients
-Delivering lectures at the "School for Patients with Diabetes and Obesity"
-Training resident doctors
-Installing and setting up insulin pumps
-Installing and analyzing continuous glucose monitoring systems
-Providing high-quality medical care to rural residents as part of medical brigades
-Working with the mobile diabetes unit team
-Providing emergency care
-Participating in scientific-practical conferences
-Publishing methodological manuals for patients with diabetes.
`
    },
        avatar: require('./ElinaKocharyan.png'),
        author: true,
        manager: true,
    },
    {
        name: {
            ru: 'Демакова Ксения',
            en: 'Ksenia Demakova'
        },
        jobTitle: {
            ru: 'Клинический психолог. Стаж диабета 1 типа 22 года',
            en: 'Clinical Psychologist. Living with Type 1 Diabetes for 22 Years'
        },
        description: {
            ru: `Стаж сахарного диабета с 2002 года. Первое образование медицинское, окончила «Йошкар-Олинский медицинский колледж» по специальности «Лечебное дело» в 2008 году. С 2008г по 2016г работала в кабинете доврачебного приема фельдшером в поликлинике г. Йшкар-Олы.
В 2019 году окончила Марийский государственный университет по специальности «Психология». В тот же год прошла профессиональную переподготовку по специальности «Клиническая психология».
С 2019 года ведет частную практику, осуществляет психологическую помощь и поддержку пациентам с сахарным диабетом. На протяжении всей работы постоянно повышает уровень своих знаний в области психотерапии. В своей практике применяет и имеет соответствующую квалификацию по следующим направлениям: когнитивно-поведенческая психотерапия, терапия принятия и ответственности (АСТ-терапия), семейная системная психотерапия в области детско-родительских отношений. Имеет квалификацию консультанта по коррекции веса и психологии пищевого поведения, опыт работы с нарушением и расстройством пищевого поведения.
С 2022 года является диа-куратором лагеря ФОКС КЭМП Черное море. Где помогает детям с сахарным диабетом жить свою полноценную и насыщенную жизнь.Проводник в принятии диабета, дает уверенность и силы поверить в себя каждому ребенку.
С 2023 года работаю в ГБУ РМЭ “Республиканская клиническая больница” в отделении эндокринологии совместно с врачом-эндокринологом веду “Школу для пациентов с сахарным диабетом`,
            en: `I am a clinical psychologist who has been living with type 1 diabetes since 2002. I was 16 years old when I was diagnosed with diabetes. My own diagnosis led me to explore the psychology of people with diabetes more deeply. My first degree was in medicine, specializing in general medicine as a paramedic. I worked for 10 years in our city’s polyclinic, helping people within my competence and capabilities. After the birth of my son, I enrolled in the Faculty of Psychology and Education, but during my studies, I realized that my true calling was elsewhere. I then changed my qualification and obtained a degree in clinical psychology.
In the summer of 2022, I met the team at FoxCamp. I worked as a diabetes counselor during three wonderful camp sessions. Since 2023, I have been the senior diabetes counselor at FOX CAMP Black Sea. Additionally, since 2023, I have been working at the Republic Mari El Clinical Hospital in the endocrinology department, where I co-lead the "School for Patients with Diabetes" with an endocrinologist.
In my practice, I use cognitive-behavioral therapy, which helps address beliefs and attitudes. I will help you accept your diabetes and live in harmony with it and yourself.
`,
        },
        avatar: require('./KseniaDemakova.png'),
        avatarBig: require('./KseniaDemakova.png'),
        manager: true,
    },
    {
        name: {
            ru: 'Григорян Нарине',
            en: 'Narine Grigoryan'
        },
        jobTitle: {
            ru: 'Кандидат биологических наук, доцент кафедры медицинской биологии и генетики Рост ГМУ',
            en: 'PhD in Biological Sciences, Associate Professor at the Department of Medical Biology and Genetics, RostGMU',
        },
        description: {
            ru: `Преподает в  РостГМУ. Общий стаж работы: 31 г. 8 мес.
            Стаж работы по специальности: 18 лет 8 мес. Ученая степень: Кандидат биологических наук.
            Направление подготовки: Биофизика`,
            en: `Narine Grigoryan is an Associate Professor at Rostov State Medical University (RostGMU). She has a total of 31 years and 8 months of professional experience, with 18 years and 8 months of specialization in her field. She holds a PhD in Biological Sciences, with a focus on Biophysics.`,
        },
        avatar: require('./NarineGrigoryan.png'),
        manager: true,
    },
    {
        name: {
            ru: 'Мария Чернышева',
            en: 'Maria Chernysheva'
        },
        jobTitle: {
            ru: 'Врач-эндокринолог',
            en: 'Endocrinologist',
        },
        description: {
            ru: `Образование: 1996 год — АГМУ им. Асфендиярова. 2006 год — КазНМУ.
            2016 год — Университет Копенгагена (диабетология).
            Повышение квалификации: • научная неделя (Дания, Копенгаген) – 2015,2016,2017,2018,2019,2020
            • семинары Москва
            • современные подходы к ведению пациентов с СД, Стамбул 2018,2019
            • диплом Копенгагенского университета по специальности «Диабетология» — 2016
            • научные конференции (Стокгольм, Мюнхен, Берлин, Лиссабон, Барселона, Дубай, Абу-Даби, Мадрид).
            Дополнительная информация:
            • Член Европейской Ассоциации по Изучению Диабета EASD
            • Член Американской Диабетической Ассоциации ADA
            • Автор научный статей
            • Одна из разработчиков методических рекомендаций по диагностике и лечению Сахарного Диабета
            • Спикер на национальных, региональных и локальных научных конференциях
            • Постерные доклады на EASD 2018,2019`,
            en: `Education:
1996: Asfendiyarov Kazakh National Medical University
2006: Kazakh National Medical University (KazNMU)
2016: University of Copenhagen (Diabetology)
Advanced Training:
Scientific Week (Denmark, Copenhagen): 2015, 2016, 2017, 2018, 2019, 2020
Seminars in Moscow
Modern Approaches to Managing Patients with Diabetes Mellitus, Istanbul: 2018, 2019
Diploma in Diabetology, University of Copenhagen: 2016
Scientific Conferences (Stockholm, Munich, Berlin, Lisbon, Barcelona, Dubai, Abu Dhabi, Madrid)
Additional Information:
Member of the European Association for the Study of Diabetes (EASD)
Member of the American Diabetes Association (ADA)
Author of scientific articles
Co-developer of guidelines for the diagnosis and treatment of diabetes mellitus
Speaker at national, regional, and local scientific conferences
Poster presentations at EASD: 2018, 2019`,
        },
        avatar: require('./MariaChernysheva.png'),
        manager: true,
    },
    {
        name: {
            ru: 'Виктория Дударева',
            en: 'Viktoria Dudareva'
        },
        jobTitle: {
            ru: 'Врач-диетолог, соучредитель ассоциации диетологи Дона',
            en: 'Nutritionist, Co-Founder of Regional Nutritionist Association ',
        },
        description: {
            ru: `Специализация: Диетотерапия ожирения; коррекция дефицита микронутриентов (витаминов, минералов);
            питание при беременности; пищевая аллергия; заболеваниях желудочно-кишечного тракта;
            эндокринной патологии (сахарный диабет, гипотиреоз); лечение выпадения волос;
            рациональное (оптимальное) питание здоровых лиц и ряд других.
            Образование и карьера: 2010 г. – РостГМУ, лечебное дело, диплом с отличием.
            2011 г. – интернатура по специальности «терапия».
            2012 г. – профессиональная переподготовка по специальности «диетология»,
            действующий сертификат подтвержден в 2017 году.
            Имеет более 70 научных статей в соавторстве, размещенных в РИНЦ.
            Опыт практической работы с пациентами по вопросам диетотерапии с 2012 года.
            Соучредитель Ассоциации диетологов Дона.
            Являлась руководителем молодежного научного кружка кафедры ЗОЖ и диетологии 9 лет,
            студенты которого трижды в 2016, 2017 и 2018 годах стали призерами Всероссийского конкурса
            «Будь здоров», проводимого при поддержке Совета Федерации Федерального собрания Российской Федерации,
            а также весь коллектив авторов и руководителей получил Благодарственное письмо от
            Министра здравоохранения Ростовской области.`,
            en: `Specialization:
Diet therapy for obesity
Correction of micronutrient deficiencies (vitamins, minerals)
Nutrition during pregnancy
Food allergies
Gastrointestinal diseases
Endocrine pathology (diabetes mellitus, hypothyroidism)
Treatment of hair loss
Rational (optimal) nutrition for healthy individuals
And other related areas
Education and Career:
2010: Rostov State Medical University (RostGMU), General Medicine, Diploma with Honors
2011: Internship in Therapy
2012: Professional retraining in Dietetics, current certification confirmed in 2017
Additional Information:
Co-author of over 70 scientific articles published in the Russian Science Citation Index (RSCI)
Practical experience in diet therapy since 2012
Co-Founder of the Don Dietitians Association
Led the Youth Scientific Circle of the Department of Healthy Lifestyle and Dietetics for 9 years; students won prizes in the All-Russian competition "Be Healthy" in 2016, 2017, and 2018, organized with the support of the Federation Council of the Federal Assembly of the Russian Federation. The entire team of authors and leaders received a letter of gratitude from the Minister of Health of the Rostov Region.
`,
        },
        avatar: require('./ViktoriaDudareva.jpg'),
        manager: true,
    },
    {
        name: {
            ru: 'Ангелина Воропай',
            en: 'Angelina Voropay'
        },
        jobTitle: {
            ru: 'К.м.н , врач детский эндокринолог, врач-эндокринолог.',
            en: 'PhD in Medicine, Pediatric Endocrinologist, Endocrinologist',
        },
        description: {
            ru: `Образование Ростовский государственный медицинский университет 2010
            Педиатрия Базовое образование 2012 Педиатрия Ординатура 2015 Педиатрия Аспирантура
            2016 Эндокринология Интернатура 2017 Детская эндокринология Циклы переподготовки
            2017 Педиатрия Повышение квалификации`,
            en: `Education:
2010: Pediatric Medicine, Rostov State Medical University (RostGMU) - Basic Medical Education
2012: Pediatric Medicine, Residency
2015: Pediatric Medicine, Postgraduate Studies
2016: Endocrinology, Internship
2017: Pediatric Endocrinology, Retraining Cycles
2017: Pediatric Medicine, Advanced Training
`,
        },
        avatar: require('./AngelinaVoropai.jpg'),
        manager: true,
    },
]